export default {
    nav: {
        dashboard: "仪表盘",
        index: "通知中心",
        message: "短消息",
        phone: "手机",
        email: "邮箱",
        wechat: "微信",
    },
    message: {
        all: "全部",
        toolbar: {
            back: "返回",
            refresh: "刷新",
            read_config: "设为已读",
            delete: "删除",
            read: "已读",
            unread: "未读",
            normal: "普通",
            important: "重要",
            urgent: "紧急",
            previous: "上一条",
            next: "下一条",
            search: "搜索",
        },
        table: {
            detail: "详情",
            remark: "备注",
            remark_placeholder: "请输入备注",
            link: "链接",
            del_mul_message: "是否要删除对应消息",
            del_message: "是否要删除该消息",
            no_data: "暂无消息",
        },
        time: {
            today: "今天",
            saturday: "周六",
            friday: "周五",
            thursday: "周四",
            wednesday: "周三",
            tuesday: "周二",
            monday: "周一",
            last: "上周",
            earlier: "更早",
        },
        meta: {
            link: "链接",
            misc: "其它",
        },
    },
    phone: {
        bindPhone: "绑定手机",
        bindPhoneAlert: "绑定常用手机以接收消息通知",
        currentBind: "当前绑定",
        noBind: "未绑定",
        sameError: "两次绑定不能相同",
        number: "手机号",
        code: "验证码",
        send: "发送验证码",
        sendSuccess: "发送成功",
        confirm: "确认",

        numberPlaceholder: "请输入手机号",
        numberError: "请输入正确的手机号",
        codePlaceholder: "请输入验证码",
        codeError: "请输入正确的验证码",
    },
    email: {
        address: "邮箱",
        bindEmail: "绑定邮箱",
        bindEmailAlert: "绑定常用邮箱以接收消息通知",
        currentBind: "当前绑定",
        noBind: "未绑定",
        sameError: "两次绑定不能相同",
        sendEmailSuccess: "已向您的邮箱{email}发送了一封验证邮件，点击验证链接即可绑定成功！邮件有效期为7天。",
        verifyContent: `<h1>等待验证</h1><div>请点击发送至您邮箱(<b>{url}</b>)中的验证链接以完成验证。<br>该链接有效期为24小时；超过此期限，您将需要重新提交验证请求。</div>`,

        addressPlaceholder: "请输入邮箱地址",
        addressError: "请输入正确的邮箱地址",
        codePlaceholder: "请输入验证码",
        codeError: "请输入正确的验证码",
        code: "验证码",
        send: "发送验证码",
        sendSuccess: "发送成功",
        confirm: "确认",
    },
    wechat: {
        noBind: "未绑定",
        alreadyBind: "已绑定",
        bindTip: "打开微信扫一扫，自动关注公众号<br>重要消息将通过微信推送",
        cancelBind: "取消绑定",
        unbind_confirm: "是否要取消绑定？",
    },
};
