<template>
    <router-view />
</template>

<script>
import User from "@iruxu/rx-common/utils/user";
import { mapMutations } from "vuex";
export default {
    name: "App",
    props: [],
    components: {},
    data: function () {
        return {};
    },
    watch: {},
    methods: {
        ...mapMutations({
            setApp: "SET_APP",
            setLang: "SET_LOCALE",
        }),
    },
    created: function () {
        // 生成特征码
        User.generateFingerprint();
    },
    mounted: function () {
        if (User.isLogin()) {
            this.$store.dispatch("getProfile").then((res) => {
                if (res.lang && User.getLocale() !== res.lang) {
                    User.setLocale(res.lang);
                    location.reload();
                } else if (!res.lang && User.getLocale() !== "en-US") {
                    User.setLocale("en-US");
                    location.reload();
                }
            });
        }

        const search = new URLSearchParams(document.location.search);
        if (search.has("app")) {
            // this.setApp(search.get("app"));
            sessionStorage.setItem("app", search.get("app"));
        }
    },
};
</script>

<style lang="less">
@import "@/assets/css/app.less";
</style>
