export default {
    nav: {
        dashboard: "Dashboard",
        index: "Notification",
        message: "Message",
        phone: "Phone",
        email: "Email",
        wechat: "WeChat",
    },
    message: {
        all: "All",
        toolbar: {
            back: "Return",
            refresh: "Refresh",
            read_config: "Set as read",
            delete: "Delete",
            read: "Read",
            unread: "Unread",
            normal: "Normal",
            important: "Important",
            urgent: "Urgent",
            previous: "Previous message",
            next: "Next message",
            search: "Search",
        },
        table: {
            detail: "Detail",
            remark: "Remark",
            remark_placeholder: "Please enter the remark",
            link: "Link",
            del_mul_message: "Do you want to delete the corresponding message?",
            del_message: "Do you want to delete this message?",
            no_data: "No Message",
        },
        time: {
            today: "Today",
            saturday: "Saturday",
            friday: "Friday",
            thursday: "Thursday",
            wednesday: "Wednesday",
            tuesday: "Tuesday",
            monday: "Monday",
            last: "Last Week",
            earlier: "Earlier",
        },
        meta: {
            link: "Link",
            misc: "Misc",
        },
    },
    phone: {
        bindPhone: "Bind Phone",
        bindPhoneAlert: "Bind your phone to receive message notifications",
        currentBind: "Current",
        noBind: "- None -",
        sameError: "The two bindings cannot be the same",
        number: "Phone number",
        code: "Code",
        send: "Send Code",
        sendSuccess: "Send Success",
        confirm: "Confirm",

        // UI Errors
        numberPlaceholder: "Please enter your phone number",
        numberError: "Please enter a valid phone number",
        codePlaceholder: "Please enter the verification code",
        codeError: "Please enter the correct verification code",
    },
    email: {
        address: "Email",
        bindEmail: "Bind Email",
        bindEmailAlert: "Bind your email to receive message notifications",
        currentBind: "Current",
        noBind: "- None -",
        sameError: "The two bindings cannot be the same",
        sendEmailSuccess:
            "A verification email has been sent to your email {email}, click the verification link to bind successfully! The email is valid for 7 days.",
        verifyContent: `<h1>Waiting for Verification</h1><div>Please click on the verification link sent to your email address (<b>{url}</b>) to complete the verification.<br>This link is valid for 7 days; if exceeded, you will need to resubmit the verification request.</div>`,

        addressPlaceholder: "Please enter your email address",
        addressError: "Please enter a valid email address",
        codePlaceholder: "Please enter the verification code",
        codeError: "Please enter the correct verification code",
        code: "Code",
        send: "Send Code",
        confirm: "Confirm",
    },
    wechat: {
        noBind: "No Bind",
        alreadyBind: "You have already bound",
        bindTip:
            "Open WeChat to scan the QR code, automatically follow the official account<br>Important messages will be pushed through WeChat",
        cancelBind: "Cancel Bind",
        unbind_confirm: "Do you want to cancel the binding?",
    },
};
