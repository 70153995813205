import { i18n } from "@/locale";

const {
    global: { t },
} = i18n;

export const apps = [
    {
        value: "iruxu",
        label: t("notification.message.all"),
        key: "iruxu",
        pinned: true,
    },
    // TODO: Add your app here and update external link
    {
        value: "miipet",
        label: t("common.app.miipet"),
        key: "miipet",
        pinned: false,
        external: "https://miipet.com",
    },
    {
        value: "2kog",
        label: t("common.app.2kog"),
        key: "2kog",
        pinned: false,
        external: "https://2kog.com",
    },
];
