export default {
    // Application
    app: {
        miipet: "MiiPet",
        "2kog": "2KOG",
    },

    // Route Name
    routes: {
        // Account
        usernameLogin: "Login - Username",
        usernameRegister: "Register - Username",

        emailLogin: "Login - Email",
        emailRegister: "Register - Email",
        emailResetPassword: "Reset Password - Email",

        phoneRegister: "Register - Phone",
        phoneLogin: "Login - Phone",
        phoneCodeLogin: "Login - Phone Code",
        phoneResetPassword: "Reset Password - Phone",

        registerCallback: "Register Success",
        loginCallback: "Login Success",
        noneCallback: "Please Register",

        // Notification
        messageTitle: "Message",
        messageDesc: "System message from all applications",

        emailTitle: "Email Bind",
        emailDesc: "Bind your email to receive important notifications.",

        phoneTitle: "Phone Bind",
        phoneDesc: "Bind your phone to receive important notifications.",

        wechatTitle: "WeChat Bind",
        wechatDesc: "Receive important notifications in real time through WeChat.",

        // Document
        termsTitle: "Privacy Policy and Terms of Service",

        // Security
        logTitle: "Log Audit",
        logDesc: "Logs from all apps",
        deviceTitle: "Device Management",
        deviceDesc: "Manage all your logged-in devices",
        passwordTitle: "Change Password",
        passwordDesc: "Change your login password",
        destroyTitle: "Deregister account",
        destroyDesc: "Are you sure you want to cancel your account?",

        // Profile
        addressTitle: "Address Management",
        addressDesc: "Manage your addresses here",

        userTitle: "Personal Profile",
        userDesc: "Manage your user profile",

        infoTitle: "Basic Information",
        infoDesc: "Manage your basic information",

        profileTitle: "Profile Details",
        profileDesc: "Manage your profile details",

        connectTitle: "Connect",
        connectDesc: "Manage your account connections",
    },

    // messagebox
    messagebox: {
        title: "Kind tips",
        success: "Operation successful",
        error: "Operation failed",
        confirm: "Confirm",
        cancel: "Cancel",
        copySuccess: "Copy Success",
    },

    table: {
        add: "Add",
        edit: "Edit",
        refresh: "Refresh",
        search: "Search",
        start_time: "Start date",
        end_time: "End date",
        op: "Operation",
        copy: "Copy",
        remark: "Remark",
        remark_placeholder: "Please enter the remark",
        remove: "Remove",
        removeAlert: "Are you sure you want to remove this record?",
        shortcuts: {
            week: "Last week",
            month: "Last month",
            month_3: "Nearly 3 months",
        },
    },

    // Footer
    footer: {
        about: "About Us",
        contact: "Contact Us",
        privacy: "Privacy Policy",
        terms: "Terms of Service",
    },
};
